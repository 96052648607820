import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/home/runner/work/css/css/src/docs/node_modules/gatsby-theme-docz/src/base/Layout.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "alert"
    }}>{`Alert`}</h1>
    <p>{`Provide contextual feedback messages for typical user actions with the handful of available and flexible alert messages.
The Alert is a combination of `}<inlineCode parentName="p">{`.box`}</inlineCode>{` and color classes.`}</p>
    <p>To see all the colors and their variations go to the <a className='pink' href='/styleguide'>Styleguide page</a></p>
    <div className='box background-green white'>
  <p className='white'>
    <i className="fas fa-check mr-2"></i> I`m a good Alert!
  </p>
    </div>
    <div className='box background-red white'>
  <p className='white'>
    <i className="fas fa-times mr-2"></i> I`m a bad Alert!
  </p>
    </div>
    <div className='box background-blue white'>
  <p className='white'>
    <i className="fas fa-info-circle mr-2"></i> I`m a info Alert!
  </p>
    </div>
    <pre><code parentName="pre" {...{
        "className": "language-html"
      }}>{`<div class='box background-green white'>
  <!-- Some HTML -->
</div>
<div class='box background-red white'>
  <!-- Some HTML -->
</div>
<div class='box background-blue white'>
  <!-- Some HTML -->
</div>
`}</code></pre>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      